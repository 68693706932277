import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PackagesService } from '../services/packages.service';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.sass']
})
export class PackagesComponent implements OnInit {
  packages: any = [];

  constructor(private router: Router, private packagesService:PackagesService) { }

  ngOnInit(): void {
    this.packagesService.getPackages$().subscribe(response=>{
      this.packages = response;
    });
  }

  goToCheckout(id: string): void {
    this.router.navigate([`/checkout/${id}`]);
  }

}
