import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AgencyApiService } from 'src/app/agency-api.service';

@Component({
  selector: 'app-agency-register',
  templateUrl: './agency-register.component.html',
  styleUrls: ['./agency-register.component.sass']
})
export class AgencyRegisterComponent implements OnInit {

  // @Input() profile: any;
  profile: any;
  // Strict compilation flag bypassed with ! since it is a boolean that has a default value.
  @Input() registrationComplete!: BehaviorSubject<boolean>;
  hasApiError = false;
  completeProfile: any;

  constructor(private agencyApi: AgencyApiService, private auth: AuthService, private route: Router) { }

  form = new FormGroup({});
  model: any = {};

  options: FormlyFormOptions = {};

  fields: FormlyFieldConfig[] = [{
    key: 'Name',
    type: 'input',
    templateOptions: {
      placeholder: 'Full Name',
      require: true,
    },
  },
  {
    key: 'ContactPhone',
    type: 'phoneInput',
    templateOptions:{
      placeholder: 'Phone Number were we can reach you',
      required: true,
    },
  }];

  ngOnInit(): void {
  }

  

  onSubmit(): void {
    
    // this.profile.name = this.model.Name;
    // this.profile.contactPhone = this.model.ContactPhone;
    this.auth.user$.pipe(take(1)).subscribe((profile) => {
 

      let profilenfo = {
        name: this.model.Name,
        contactPhone: this.model.ContactPhone,
        agency: localStorage.getItem("agency"),
        email: profile?.email,
        nickname: profile?.nickname,
        picture: profile?.picture
      }

      this.agencyApi.addCustomer$(profilenfo).subscribe({
        next: (res) => {
          
          this.hasApiError = false;
          this.completeProfile = res;
          //this.registrationComplete.next(true);
          this.route.navigate(["/external-packages"]);
        },
        error: () => this.hasApiError = true,
      });
    });

    
  }

}
