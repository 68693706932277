import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ReferralFormComponent } from './referral-form/referral-form.component'; // Probably can be removed
import { RefCodeComponent } from './auth/ref-code/ref-code.component'
import { ErrorComponent } from './error/error.component'
import { ThankyouComponent } from './thankyou/thankyou.component'
import { AuthComponent } from './auth/auth.component';
import { ProfileComponent } from './auth/profile/profile.component';


import { AuthGuard } from '@auth0/auth0-angular';
import { AgencyHomeComponent } from './agency/agency-home/agency-home.component';
import { AgencyRegisterComponent } from './agency/agency-register/agency-register.component';
import { PackagesComponent } from './packages/packages.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ExternalPackagesComponent } from './external-packages/external-packages.component';
import { UploadComponent } from './upload/upload.component';
import { MySocialComponent } from './my-social/my-social.component';

export const appRoutes: Routes =  [
    // {path: '', component: AgencyHomeComponent},
    { path: '', component: AgencyHomeComponent, pathMatch: 'full' },
    {path: 'home', component: HomeComponent},
    {path: 'referral', component: ReferralFormComponent},
    {path: 'ref-code', component: RefCodeComponent},
    {path: 'thankyou', component: ThankyouComponent},
    {path: 'auth', component: AuthComponent},
    {path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
    {path: 'agency/:agency', component: AgencyHomeComponent},
    {path: 'register', component: AgencyRegisterComponent},
    // {path: 'agnecy/:agency/register', component: AgencyRegisterComponent},
    {path: 'packages', component: PackagesComponent, canActivate: [AuthGuard]},
    {path: 'external-packages', component: ExternalPackagesComponent},
    {path: 'checkout/:id', component: CheckoutComponent},
    {path: 'upload', component: UploadComponent, canActivate: [AuthGuard]},
    {path: 'my-social', component: MySocialComponent, canActivate: [AuthGuard]},
    {path: '**', component: ErrorComponent } // Wildcard route to error page
];