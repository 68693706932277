import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { AgencyApiService } from './agency-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  title = 'devshield';
  loading = false;

  constructor(public auth: AuthService, private route: Router, public agencyApiService:AgencyApiService) {

  }

  ngOnInit() {
    this.agencyApiService.isLoading.subscribe(value => {
      this.loading = value
    });
  }

  onlogoClick() {
    this.auth.isAuthenticated$.subscribe(value => {
      if (value) {
        this.route.navigate(["/external-packages"]);
      } else {
        this.route.navigate(["/"]);
      }
    });
  }
}
