import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@auth0/auth0-angular';

const jwt = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AgencyService {

  private uriseg = 'https://devapi.devshield.io/api';
  private decodedToken: any;
  private httpClientExtra: HttpClient;

  constructor(private http: HttpClient, public auth: AuthService, private handler: HttpBackend) { 
    this.httpClientExtra = new HttpClient(handler);
  }

  public valAgency(agency: any): Observable<any> {
    const URI = this.uriseg + '/agency/valAgency';
    return this.httpClientExtra.post(URI, agency);
  }

  
  public valUserRegistration(profile: any): Observable<any>{
    let headers
    let URI =  this.uriseg + '/agency/valUserRegistration';
    this.auth.getAccessTokenSilently().subscribe(token => {
    headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
   
    
    });
    return this.http.post(URI, profile, {headers : headers} );
  }

  private saveToken(token: any): any {
    console.log(token);
    this.decodedToken = jwt.decodeToken(token.token);
    console.log(this.decodedToken);
    localStorage.setItem('auth_tkn', token.token);
    localStorage.setItem('auth_meta', JSON.stringify(this.decodedToken));
    return token;
  }

  private getSavedToken(): any
  {
    const token = localStorage.getItem('auth_tkn');
    return token;
  }
}
