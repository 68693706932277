<section class="bg-dark-section">
    <div class="container upload-form-container">
        <div class="col-md-8">
        <form novalidate (ngSubmit)="submitForm()" [formGroup]="uploadForm">
            <div class="col-md-12 col-xs-12">
                <label class="col-md-3 col-xs-3 text-right">Package<label class="required">*</label></label>
                <div class="col-md-8 col-xs-8">
                    <mat-form-field appearance="fill">
                        <mat-select formControlName="packages" (selectionChange)="packageSelect($event.value)">
                            <mat-option *ngFor="let package of purchasedPackages" [value]="package.name">
                                {{package.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-12 col-xs-12" *ngIf="socialList.length">
                <label class="col-md-3 col-xs-3 text-right">Social<label class="required">*</label></label>
                <div class="col-md-8 col-xs-8">
                    <mat-form-field appearance="fill">
                        <mat-select formControlName="social">
                            <mat-option *ngFor="let social of socialList" [value]="social">
                                {{social}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-12 col-xs-12 d-flex">
                <label class="col-md-3 col-xs-3 text-right">Upload<label class="required">*</label></label>
                <div class="col-md-8 col-xs-8">
                    <input class="input-text" type="file" (change)="handleFileInput($event.target)" placeholder="CSV upload" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" #fileUpload/>
                    <span *ngIf="!uploadForm.get('fileUpload')?.valid && uploadForm.get('fileUpload')?.touched" class="help-block">Please upload file
                    </span>
                </div>
            </div>
            <span class="action-buttons">
                <button type="button" class="btn create-btn" (click)="resetForm()">
                    Reset
                </button>
                <button type="submit" class="btn create-btn" [ngClass]="{ disabled: !uploadForm.valid }"
                    [disabled]="!uploadForm.valid">
                    Submit
                </button>
            </span>
          </form>
        </div>
    </div>
</section>
