import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-package-option',
  templateUrl: './package-option.component.html',
  styleUrls: ['./package-option.component.sass']
})
export class PackageOptionComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToCheckout(): void {
    this.router.navigate(['/checkout'])
  }

}
