<div class="row checkout-container">
  <div class="col-md-6">
    <!-- Package Details -->
    <div class="col-md-12">
      <h2 class="checkout-title">Package Details</h2>
      <div class="col-md-12 col-xs-12">
        <span class="package-name">
          <label class="col-md-3 col-xs-3">Package Name </label>
          <div class="col-md-8 col-xs-8">
            {{ package?.name }}
          </div>
        </span>
      </div>
    </div>
    <!-- Card Details -->
    <div class="col-md-12">
      <h2 class="checkout-title">Card Details</h2>
        <form novalidate [formGroup]="stripeTest">
          <div class="col-md-12 col-xs-12">
            <label class="col-md-3 col-xs-3">Cardholder name<label class="required">*</label></label>
            <div class="col-md-9 col-xs-9">
              <input class="input-text" type="text" formControlName="name" placeholder="Cardholder Name" />
            </div>
          </div>
          <div class="col-md-12 col-xs-12">
            <label class="col-md-3 col-xs-3">Note<label class="required">*</label></label>
            <div class="col-md-9 col-xs-9">
              <input class="input-text" type="text" formControlName="description" placeholder="Note" />
            </div>
          </div>
          <div class="col-md-12 col-xs-12">
            <label class="col-md-3 col-xs-3">Card number<label class="required">*</label></label>
            <div class="col-md-9 col-xs-9">
              <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
            </div>
          </div>
        </form>
    </div>
  </div>
  <!-- Billing Address -->
  <div class="col-md-6">
    <h2 class="checkout-title">Billing Address</h2>
    <div class="user-details">
      <form [formGroup]="userDetailForm">
        <div class="col-md-12 col-xs-12">
          <label class="col-md-3 col-xs-3">Name<label class="required">*</label></label>
          <div class="col-md-8 col-xs-8">
            <input class="input-text" type="text" [ngClass]="{
              'formFox-control-error':
                userDetailForm.get('userName')?.touched &&
                !userDetailForm.get('userName')?.valid
            }" formControlName="userName" />
            <span *ngIf="
              !userDetailForm.get('userName')?.valid &&
              userDetailForm.get('userName')?.touched
            " class="help-block">Please enter name
            </span>
          </div>
        </div>

        <div class="col-md-12 col-xs-12">
          <label class="col-md-3 col-xs-3">Email<label class="required">*</label></label>
          <div class="col-md-8 col-xs-8">
            <input class="input-text" type="email" [ngClass]="{
              'formFox-control-error':
                userDetailForm.get('userEmail')?.touched &&
                !userDetailForm.get('userEmail')?.valid
            }" formControlName="userEmail" />
            <span *ngIf="
              !userDetailForm.get('userEmail')?.valid &&
              userDetailForm.get('userEmail')?.touched
            " class="help-block">Please enter valid email
            </span>
          </div>
        </div>

        <div class="col-md-12 col-xs-12">
          <label class="col-md-3 col-xs-3">Address Line 1<label class="required">*</label></label>
          <div class="col-md-8 col-xs-8">
            <input class="input-text" type="text" [ngClass]="{
              'formFox-control-error':
                userDetailForm.get('address1')?.touched &&
                !userDetailForm.get('address1')?.valid
            }" formControlName="address1" />
            <span *ngIf="
              !userDetailForm.get('address1')?.valid &&
              userDetailForm.get('address1')?.touched
            " class="help-block">Please enter name
            </span>
          </div>
        </div>

        <div class="col-md-12 col-xs-12">
          <label class="col-md-3 col-xs-3">Address Line 2</label>
          <div class="col-md-8 col-xs-8">
            <input class="input-text" type="text" formControlName="address2" />
          </div>
        </div>

        <div class="col-md-12 col-xs-12">
          <label class="col-md-3 col-xs-3">City<label class="required">*</label></label>
          <div class="col-md-8 col-xs-8">
            <input class="input-text" type="text" [ngClass]="{
              'formFox-control-error':
                userDetailForm.get('city')?.touched &&
                !userDetailForm.get('city')?.valid
            }" formControlName="city" />
            <span *ngIf="
              !userDetailForm.get('city')?.valid &&
              userDetailForm.get('city')?.touched
            " class="help-block">Please enter city name
            </span>
          </div>
        </div>

        <div class="col-md-12 col-xs-12">
          <label class="col-md-3 col-xs-3">State<label class="required">*</label></label>
          <div class="col-md-8 col-xs-8">
            <input class="input-text" type="text" [ngClass]="{
              'formFox-control-error':
                userDetailForm.get('state')?.touched &&
                !userDetailForm.get('state')?.valid
            }" formControlName="state" />
            <span *ngIf="
              !userDetailForm.get('state')?.valid &&
              userDetailForm.get('state')?.touched
            " class="help-block">Please enter state name
            </span>
          </div>
        </div>

        <div class="col-md-12 col-xs-12">
          <label class="col-md-3 col-xs-3">Zip<label class="required">*</label></label>
          <div class="col-md-8 col-xs-8">
            <input class="input-text" type="text" [ngClass]="{
              'formFox-control-error':
                userDetailForm.get('zip')?.touched &&
                !userDetailForm.get('zip')?.valid
            }" formControlName="zip" mask="00000" [showMaskTyped]="true" required />
            <span *ngIf="
              !userDetailForm.get('zip')?.valid &&
              userDetailForm.get('zip')?.touched
            " class="help-block">Please enter Zip
            </span>
          </div>
        </div>

        <div class="col-md-12 col-xs-12">
          <label class="col-md-3 col-xs-3 align-label">Phone Number<label class="required">*</label></label>
          <div class="col-md-8 col-xs-8">
            <input class="col-md-12 col-xs-12 input-text" type="text" formControlName="userPhone" mask="(000) 000-0000"
              [showMaskTyped]="true" />
            <span *ngIf="
              !userDetailForm.get('userPhone')?.valid &&
              userDetailForm.get('userPhone')?.touched
            " class="help-block">Please enter valid phone number
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="col-12">
    <span class="action-buttons">
      <button type="button" class="btn create-btn" [routerLink]="['/external-packages']">
        Cancel
      </button>
      <button type="button" class="submit-button btn btn-default" [ngClass]="{ disabled: stripeTest.invalid || userDetailForm.invalid}"
        [disabled]="stripeTest.invalid || userDetailForm.invalid" (click)="inviteUser(userDetailForm.value)">
        Pay ${{ package?.price + package?.tax }}
      </button>
    </span>
  </div>
</div>