import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PackagesService } from '../services/packages.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-my-social',
  templateUrl: './my-social.component.html',
  styleUrls: ['./my-social.component.sass']
})
export class MySocialComponent implements OnInit {
  filterForm!: FormGroup;
  socialList: any[] = [];
  gridData: any = [];
  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: 'id',
    orderDir: 'desc'
  };
  columndefs : any[] = ['name', 'createddate'];
  constructor(private fb: FormBuilder, private packagesService: PackagesService) {}

  ngOnInit(): void {
    this.packagesService.getUploadedFiles().subscribe((response:any) => {
      this.gridData = response;
      this.page.limit = response.length;
      this.page.count = response.length;
    });
    
    this.socialList = ['Clubhouse', 'LinkedIn', 'Twitter'];
    this.filterForm = this.fb.group({
      social: [null, Validators.compose([Validators.required])],
      fromDate: [null, Validators.compose([Validators.required])],
      toDate: [null, Validators.compose([Validators.required])]
    });
  }

  filter() {
    console.log(this.filterForm.value);
  }

  resetForm() {
    this.filterForm.reset();
  }

  downloadFile(element: any) {
    this.packagesService.getUploadedFileById(element.id).subscribe((data: Blob) => {
      var file = new Blob([data], { type: ' application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      var fileURL = URL.createObjectURL(file);

      //window.open(fileURL);
      var a = document.createElement('a');
      a.href = fileURL;
      a.target = '_blank';
      a.download = element.name ? element.name : 'report.xlsx';
      document.body.appendChild(a);
      a.click();
    });
  }
}
