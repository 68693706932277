<div class="slider" id="top">
    <div class="wrap-header intro">
      <!-- Start slide-->
      <div class="slide bg-mask background-image full-vh" style="background-image: url('assets/img/mtnbkg2.jpg')">
        <div class="container-slide vertical-align center small-text">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h1 class="heading-title-big">
                   thank you</h1>
                <div class="description-slide">We will review your answers and one of our team will reachout to discuss in 1 - 2 business days.</div>
                <div class="buttons-section"><a class="smooth-scroll btn dark-btn large-btn" href="index.html">Return to Home</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
     </div>
  </div>
