<div class="slider" id="top">
    <div class="wrap-header">
      <!-- Start slide-->
      <div class="slide bg-mask background-image full-vh" >
            <div class="container-slide vertical-align center">

                
                    <div>Welcome please login or if this is your first time here please register and we will get started</div>


                    <div>
                        <app-login-button></app-login-button>
                    </div>
                    <div>
                        <app-signup-button></app-signup-button>
                    </div>
                

               


            </div>
        </div>
    </div>
</div>
