import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import * as config from '../../auth_config.json';

@Injectable({
  providedIn: 'root'
})
export class AgencyApiService {
  public isLoading:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  ping$(): Observable<any>{
    return this.http.get(`$`)
  }

  validateRegistration$(profile: any): Observable<any>{
    return this.http.post(`${config.apiUri}/agency/valUserRegistration`, profile);
  }

  addCustomer$(profile: any): Observable<any>{
    return this.http.post(`${config.apiUri}/agency/addCustomer`, profile)
  }
}
