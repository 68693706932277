import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import moment from 'moment';

const jwt = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private uriseg = 'http://api.devshield.io:8080/api';
  private decodedToken: any;

  constructor(private http: HttpClient) { }

  public valRefCode(userData: any): Observable<any> {
    const URI = this.uriseg + '/main/valrefcode';
    return this.http.post(URI, userData).pipe(map(token => {
      return this.saveToken(token);
    }));
  }



  private saveToken(token: any): any {
    console.log(token);
    this.decodedToken = jwt.decodeToken(token.token);
    console.log(this.decodedToken);
    localStorage.setItem('auth_tkn', token.token);
    localStorage.setItem('auth_meta', JSON.stringify(this.decodedToken));
    return token;
  }

  private getSavedToken(): any
  {
    const token = localStorage.getItem('auth_tkn');
    return token;
  }

  public submitSurvey(survey: any): Observable<any>{
    const token = this.getSavedToken();
    const headers = {Authorization: `Bearer ${token}`};
    const URI = this.uriseg + '/survey';
    return this.http.post(URI, survey, {headers});
  }

  public isAuthenticated(): boolean {
    console.log(this.decodedToken.exp);
    return moment().isBefore(moment.unix(this.decodedToken.exp));
  }
}
