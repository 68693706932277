import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AgencyApiService } from './agency-api.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class AgencyInterceptor implements HttpInterceptor {
  constructor(public agencyService: AgencyApiService, public auth: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.auth.isAuthenticated$.subscribe(value => {
      if (value) {
        this.agencyService.isLoading.next(true);
      }
    });
    
    return next.handle(request).pipe(
        finalize(() => {
            this.agencyService.isLoading.next(false);
        })
    );
  }
}