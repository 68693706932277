import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { from, Observable, of } from 'rxjs';
import * as config from '../../../auth_config.json';

@Injectable({
  providedIn: 'root'
})
export class PackagesService {
  private uriseg = 'https://devapi.devshield.io/api';
  packagesJSON = 
    [
      {
        "id": "0a7df0b1-966c-40cd-8489-2410e53c26a8",
        "name": "Vaibhav",
        "description": "Test Pkg",
        "price": 22.33,
        "tax": 20.32,
        "iconUrl": "",
        "imageUrl": "assets/img/test.jpg",
        "buttonText": "Get Started",
        "buttonUri": "",
        "active": true,
        "allowsSubscription": true,
        "subscriptionPrice": 12.3,
        "subscriptionDuration": 12,
        "expirationDate": "2022-09-10T10:21:14.104Z",
        "renew": false,
        "purchased": true
      },
      {
        "id": "0a7df0b1-966c-40cd-8489-2410e53c26a8",
        "name": "Vaibhav",
        "description": "Test Pkg",
        "price": 22.33,
        "tax": 20.32,
        "iconUrl": "",
        "imageUrl": "assets/img/test.jpg",
        "buttonText": "Get Started",
        "buttonUri": "",
        "active": true,
        "allowsSubscription": true,
        "subscriptionPrice": 12.3,
        "subscriptionDuration": 12,
        "expirationDate": "2022-09-10T10:21:14.104Z",
        "renew": false,
        "purchased": true
      },
      {
        "id": "0a7df0b1-966c-40cd-8489-2410e53c26a8",
        "name": "Vaibhav",
        "description": "Test Pkg",
        "price": 22.33,
        "tax": 20.32,
        "iconUrl": "",
        "imageUrl": "assets/img/test.jpg",
        "buttonText": "Get Started",
        "buttonUri": "",
        "active": true,
        "allowsSubscription": true,
        "subscriptionPrice": 12.3,
        "subscriptionDuration": 12,
        "expirationDate": "2022-09-10T10:21:14.104Z",
        "renew": true,
        "purchased": true
      },
      {
        "id": "0a7df0b1-966c-40cd-8489-2410e53c26a8",
        "name": "Vaibhav",
        "description": "Test Pkg",
        "price": 22.33,
        "tax": 20.32,
        "iconUrl": "",
        "imageUrl": "assets/img/test.jpg",
        "buttonText": "Get Started",
        "buttonUri": "",
        "active": true,
        "allowsSubscription": true,
        "subscriptionPrice": 12.3,
        "subscriptionDuration": 12,
        "expirationDate": "2022-09-10T10:21:14.104Z",
        "renew": true,
        "purchased": true
      },
      {
        "id": "0a7df0b1-966c-40cd-8489-2410e53c26a8",
        "name": "Vaibhav",
        "description": "Test Pkg",
        "price": 22.33,
        "tax": 20.32,
        "iconUrl": "",
        "imageUrl": "assets/img/test.jpg",
        "buttonText": "Get Started",
        "buttonUri": "",
        "active": true,
        "allowsSubscription": true,
        "subscriptionPrice": 12.3,
        "subscriptionDuration": 12,
        "expirationDate": "2022-09-10T10:21:14.104Z",
        "renew": false,
        "purchased": false
      },
      {
        "id": "0a7df0b1-966c-40cd-8489-2410e53c26a8",
        "name": "Vaibhav",
        "description": "Test Pkg",
        "price": 22.33,
        "tax": 20.32,
        "iconUrl": "",
        "imageUrl": "assets/img/test.jpg",
        "buttonText": "Get Started",
        "buttonUri": "",
        "active": true,
        "allowsSubscription": true,
        "subscriptionPrice": 12.3,
        "subscriptionDuration": 12,
        "expirationDate": "2022-09-10T10:21:14.104Z",
        "renew": false,
        "purchased": false
      },
      {
        "id": "0a7df0b1-966c-40cd-8489-2410e53c26a8",
        "name": "Vaibhav",
        "description": "Test Pkg",
        "price": 22.33,
        "tax": 20.32,
        "iconUrl": "",
        "imageUrl": "assets/img/test.jpg",
        "buttonText": "Get Started",
        "buttonUri": "",
        "active": true,
        "allowsSubscription": true,
        "subscriptionPrice": 12.3,
        "subscriptionDuration": 12,
        "expirationDate": "2022-09-10T10:21:14.104Z",
        "renew": true,
        "purchased": false
      },
      {
        "id": "0a7df0b1-966c-40cd-8489-2410e53c26a8",
        "name": "Vaibhav",
        "description": "Test Pkg",
        "price": 22.33,
        "tax": 20.32,
        "iconUrl": "",
        "imageUrl": "assets/img/test.jpg",
        "buttonText": "Get Started",
        "buttonUri": "",
        "active": true,
        "allowsSubscription": true,
        "subscriptionPrice": 12.3,
        "subscriptionDuration": 12,
        "expirationDate": "2022-09-10T10:21:14.104Z",
        "renew": true,
        "purchased": false
      }
    ];

  fileUploads: any[] = [
    {
        "id": "1oVV51g9H3nGBzp4ND0EGm8hF9sgC7l2v",
        "size": 178,
        "name": "Changes2.0.txt",
        "version": 2,
        "createdTime": "2021-09-14T14:11:29.413+05:30",
        "parents": [
            "1MFTp8Hpy3JOTvih4HaI0BpU41zRSolHz"
        ],
        "mimeType": "text/plain"
    },
    {
        "id": "18IOBCfiT2_quztCFG_fI8E_UdSQmoFG4",
        "size": 0,
        "name": "Changes2.0.txt",
        "version": 2,
        "createdTime": "2021-09-14T14:11:00.022+05:30",
        "parents": [
            "1MFTp8Hpy3JOTvih4HaI0BpU41zRSolHz"
        ],
        "mimeType": "text/plain"
    },
    {
        "id": "13-tztY-wITpyH_NizspxBSQdH6IqRac8",
        "size": 0,
        "name": "Changes2.0.txt",
        "version": 2,
        "createdTime": "2021-09-14T14:10:01.955+05:30",
        "parents": [
            "1MFTp8Hpy3JOTvih4HaI0BpU41zRSolHz"
        ],
        "mimeType": "text/plain"
    },
    {
        "id": "1tWe2VA1xET8-V28PYcDj2Ele4gKXXIko",
        "size": 178,
        "name": "Changes2.0.txt",
        "version": 2,
        "createdTime": "2021-09-14T14:09:27.247+05:30",
        "parents": [
            "1MFTp8Hpy3JOTvih4HaI0BpU41zRSolHz"
        ],
        "mimeType": "text/plain"
    },
    {
        "id": "1MFTp8Hpy3JOTvih4HaI0BpU41zRSolHz",
        "size": null,
        "name": "Vaibhav",
        "version": 6,
        "createdTime": "2021-09-14T13:45:58.436+05:30",
        "parents": [
            "0AO1e0zTGIXuPUk9PVA"
        ],
        "mimeType": "application/vnd.google-apps.folder"
    },
    {
        "id": "194NL5YnganMrsE3ZZ-lz40CM7kXoWaZp",
        "size": 25818,
        "name": "Read Me.html",
        "version": 2,
        "createdTime": "2021-09-03T18:37:57.644+05:30",
        "parents": [
            "0AO1e0zTGIXuPUk9PVA"
        ],
        "mimeType": "text/html"
    },
    {
        "id": "1JujL4C5k5djNrnTXJHJVPNG8GXpnWuOO",
        "size": 25818,
        "name": "Read Me.html",
        "version": 2,
        "createdTime": "2021-09-03T18:33:33.861+05:30",
        "parents": [
            "0AO1e0zTGIXuPUk9PVA"
        ],
        "mimeType": "application/msword"
    }
];

  constructor(private http: HttpClient, public auth: AuthService) { }

  getPackages$() {
    const customerId = this.getCustomerId();
    let headers
    //let URI =  config.apiUri + '/Package/GetPackage';
    let URI =  config.apiUri + `/Package/getAllPgk?customerId=${customerId}`;
    this.auth.getAccessTokenSilently().subscribe(token => {
    headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    });
    return this.http.get(URI, {headers : headers});
  }

  getSocialList$(packageId: any) {
    const customerId = this.getCustomerId();
    let headers
    let URI =  config.apiUri + `/package/customerMedia?customerId=${customerId}&packageId=${packageId}`;
    this.auth.getAccessTokenSilently().subscribe(token => {
    headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    });
    return this.http.get(URI, {headers : headers});
  }

  getPackageById(id: string) {
    // let packages: Array<any> = this.packagesJSON
    // // let packages: Array<any> = JSON.parse(this.packagesJSON);
    // return packages.filter(p => p.id == id)[0];
    let headers
    //let URI =  config.apiUri + '/Package/GetPackage';
    let URI =  config.apiUri + '/Package/getPgk?pkgID='+id;
    this.auth.getAccessTokenSilently().subscribe(token => {
    headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    });
    return this.http.get(URI, {headers : headers});
  }

  getStripeKey() {
    let headers
    let URI =  config.apiUri + '/Stripe/GetStripeKey';
    this.auth.getAccessTokenSilently().subscribe(token => {
      headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    });
    return this.http.get(URI);
    //return of({SecretKey: "djkasjrn72763nasdsd8", PublishableKey: "asdkj9iwkssasskle8j"});
  }

  saveStripeCheckout(payload: any) {
    let headers
    let URI =  config.apiUri + '/Stripe/CreateCharge';
    this.auth.getAccessTokenSilently().subscribe(token => {
      headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    });
    return this.http.post(URI, payload, {headers : headers} );
  }

  getBillingAddress() {
    const customerId = this.getCustomerId();
    let headers
    let URI =  config.apiUri + `/billing/getAddress?customerId=${customerId}`;
    this.auth.getAccessTokenSilently().subscribe(token => {
      headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    });
    return this.http.get(URI);
  }

  insertBillingAddress(payload: any): Observable<any>{
    let headers
    this.auth.getAccessTokenSilently().subscribe(token => {
      headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    });
    return this.http.post(`${config.apiUri}/billing/insertAddress`, payload,{headers : headers});
  }

  updateBillingAddress(payload: any): Observable<any>{
    let headers
    this.auth.getAccessTokenSilently().subscribe(token => {
      headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    });
    return this.http.post(`${config.apiUri}/billing/updateAddress`, payload);
  }

  saveFileUpload(payload: FormData): Observable<any> {
    let headers
    this.auth.getAccessTokenSilently().subscribe(token => {
      headers = new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      });
    });
    return this.http.post(`${config.apiUri}/upload/gDriveUpload`, payload,{headers : headers});
  }

  getUploadedFiles() {
    let headers
    this.auth.getAccessTokenSilently().subscribe(token => {
      headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    });
    return this.http.get(`${config.apiUri}/upload/gDriveFile`);
    //return of(this.fileUploads);
  }

  getUploadedFileById(fileId: string) {
    let headers
    this.auth.getAccessTokenSilently().subscribe(token => {
      headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    });
    return this.http.get(`${config.apiUri}/upload/downloadGDriveFile?fileID=${fileId}`, {responseType: 'blob'});
  }

  getCustomer(email: any) {
    //const email_param = encodeURIComponent(email);
    //let URI = config.apiUri + `/Customer/gCustomer?email=${email_param}`;
    let URI = config.apiUri + `/Customer/gCustomer?email=${email}`;
    return this.http.get(URI);
  }

  setData(data: any) {
    localStorage.setItem('customer_id', data.id);
    localStorage.setItem('name', data.name);
    //localStorage.setItem('agency', data.agency);
  }

  getCustomerId() {
    return localStorage.getItem('customer_id');
  }
}
