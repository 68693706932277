import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GoogleApisService } from '../services/googleapis.service';
import { NotificationService } from '../services/notification.service';
import { PackagesService } from '../services/packages.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.sass']
})
export class UploadComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload!: ElementRef;
  uploadForm!: FormGroup;
  socialList: any;
  purchasedPackages: any[] = [];

  constructor(private fb: FormBuilder,
    public googleApiService: GoogleApisService,
    private packagesService: PackagesService,
    private notificationService: NotificationService) { 
    this.uploadForm = this.fb.group({
      social: [null, Validators.compose([Validators.required])],
      packages: [null, Validators.compose([Validators.required])],
      fileUpload: [null, Validators.compose([Validators.required])]
    });
  }

  ngOnInit(): void {
    this.getPackages();
    //this.getSocialList();
  }

  submitForm() {
    const customerName = localStorage.getItem('name');
    const fileData: File = this.uploadForm.get('fileUpload')?.value;
    const socialName = this.uploadForm.get('social')?.value ? this.uploadForm.get('social')?.value : 'NA';
    const packageName = this.uploadForm.get('packages')?.value;
    const fileName = packageName+'_'+customerName+'_'+socialName+'_'+fileData.name;
    
    let formData = new FormData();
    formData.append('file', this.uploadForm.get('fileUpload')?.value, fileName);
    
    this.packagesService.saveFileUpload(formData).subscribe(response => {

      this.notificationService.success("File uploaded successfully.");
    }, (error) => {

      this.notificationService.error("There was an error while uploading the file");
    });
  }

  handleFileInput(target: any) {
    const file = target.files[0];
    this.uploadForm.patchValue({
      fileUpload: file
    });
  }

  resetForm() {
    this.uploadForm.reset();
    this.fileUpload.nativeElement.value = '';
  }

  getPackages(): void {
    this.packagesService.getPackages$().subscribe(response=>{
      const packages: any = response;
      
      this.purchasedPackages = packages.filter(
        (item:any) => {
          return (item.purchased == true && item.renew == false)? item: null;
        }
      );
    });
  }

  getSocialList(packageId:any): void {
    this.packagesService.getSocialList$(packageId).subscribe(response=>{
      this.socialList = response;
      if(this.socialList.length) {
        this.uploadForm.setControl('social', this.fb.control(null, Validators.compose([Validators.required])));
      } else {
        this.uploadForm.removeControl('social');
      }
    });
  }

  packageSelect(value: any) {
    console.log(value);
    this.getSocialList(value.id);
  }

}
