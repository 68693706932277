<!--Section our services-->
<section class="section-our-services bg-dark-section" id="services">
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-md-push-2">
          <div class="heading-title center">
            <h2 class="mb-10">Our <span>services</span></h2>
            <div class="small-desd">We create <span>awesome stuff</span></div>
            <p>The combination of beautiful and clean design, technology and user experience, give us big advantage in the market and differentiates our work.</p>
          </div>
        </div>
      </div>
      <div *ngFor="let package of dsPackages">
          <app-package-option></app-package-option>
      </div>
        
      
      <!-- <div class="row">
        <div class="item-service">
          <div class="col-md-3 icon-service bg-mask background-image" data-image="img/bg2.jpg">
            <div class="small-i"><i class="pe-7s-graph"></i></div>
            <div class="large-i"><i class="pe-7s-graph"></i></div>
          </div>
          <div class="col-md-9 content-service">
            <h2>Branding</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            <hr>
            <div class="buttons-section left"><a class="btn white-btn" href="service-single.html">Get started</a></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="item-service">
          <div class="col-md-3 icon-service bg-mask background-image" data-image="img/bg3.jpg">
            <div class="small-i"><i class="pe-7s-drop"></i></div>
            <div class="large-i"><i class="pe-7s-drop"></i></div>
          </div>
          <div class="col-md-9 content-service">
            <h2>Web <span>design</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            <hr>
            <div class="buttons-section left"><a class="btn white-btn" href="service-single.html">Get started</a></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="item-service">
          <div class="col-md-3 icon-service bg-mask background-image" data-image="img/bg4.jpg">
            <div class="small-i"><i class="pe-7s-phone"></i></div>
            <div class="large-i"><i class="pe-7s-phone"></i></div>
          </div>
          <div class="col-md-9 content-service">
            <h2>Mobile apps <span>development</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            <hr>
            <div class="buttons-section left"><a class="btn white-btn large-btn" href="service-single.html">Get started</a></div>
          </div>
        </div>
      </div> -->
    </div>
  </section>
