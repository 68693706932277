<section class="bg-dark-section">
    <div class="container my-social-container">
        <!-- <div class="row">
            <form (ngSubmit)="filter()" [formGroup]="filterForm">
                <div class="col-md-3 flex-direction-column">
                    <label>Social<label class="required">*</label></label>
                    <mat-form-field appearance="fill">
                        <mat-select formControlName="social">
                            <mat-option *ngFor="let social of socialList" [value]="social">
                                {{social}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-3 flex-direction-column">
                    <label>From Date<label class="required">*</label></label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="fromDate" [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-3 flex-direction-column">
                    <label>To Date<label class="required">*</label></label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="toDate" [matDatepicker]="picker1">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <span class="action-buttons">
                        <button type="submit" class="btn create-btn" [ngClass]="{ disabled: !filterForm.valid }"
                            [disabled]="!filterForm.valid">
                            Search
                        </button>
                        <button type="button" class="btn create-btn" (click)="resetForm()">
                            Clear
                        </button>
                    </span>
                </div>
            </form>
        </div> -->
        <div class="row data-table">
            <table mat-table [dataSource]="gridData" class="mat-elevation-z8">

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let element"> <a (click)="downloadFile(element)">{{element.name}}</a></td>
                </ng-container>
                <ng-container matColumnDef="createddate">
                    <th mat-header-cell *matHeaderCellDef> Created Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.createdTime | date: 'medium'}} </td>
                  </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="columndefs"></tr>
                <tr mat-row *matRowDef="let row; columns: columndefs;"></tr>
              </table>
        </div>
    </div>
</section>
