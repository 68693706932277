import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-packages-wrapper',
  templateUrl: './packages-wrapper.component.html',
  styleUrls: ['./packages-wrapper.component.sass']
})
export class PackagesWrapperComponent implements OnInit {

  //Sample Package List
  public dsPackages: any =  [
      {
        package:{
          Title: "Discovery Package",
          Description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
          Cost: 3500.00,
          Icon: "",
          ID: 12345,
          ButtonText: "Buy",
        }
      },
      {
        package:{
          Title: "Engage Package",
          Descrition: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
          Cost: -1,
          Icon: "",
          ID: 12346,
          ButtonText: "Get Started",
        }
      }
    ]
  
  constructor() { }

  ngOnInit(): void {
  }

}
