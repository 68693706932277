import { FormlyPhoneInput } from './auth/survey/formly-phone-input';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppComponent } from './app.component';
import { ReferralFormComponent } from './referral-form/referral-form.component';
import { HomeComponent } from './home/home.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { AuthComponent } from './auth/auth.component';

import { MyAuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { ErrorComponent } from './error/error.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { AuthenticationButtonComponent } from './auth/authentication-button/authentication-button.component';
import { SignupButtonComponent } from './auth/signup-button/signup-button.component';
import { LoginButtonComponent } from './auth/login-button/login-button.component';
import { LogoutButtonComponent } from './auth/logout-button/logout-button.component';
import { ProfileComponent } from './auth/profile/profile.component';
import { AgencyHomeComponent } from './agency/agency-home/agency-home.component';
import { AgencyRegisterComponent } from './agency/agency-register/agency-register.component';
import { WelcomeComponent } from './agency/welcome/welcome.component';
import { PackagesComponent } from './packages/packages.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthModule, AuthHttpInterceptor } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';

import { ExternalPackagesComponent } from './external-packages/external-packages.component';
import { NgxStripeModule } from 'ngx-stripe';

import { PackagesWrapperComponent } from './packages/packages-wrapper/packages-wrapper.component';
import { PackageOptionComponent } from './packages/package-option/package-option.component';
import { UploadComponent } from './upload/upload.component';
import { MySocialComponent } from './my-social/my-social.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { APP_BASE_HREF } from '@angular/common';
import { AgencyInterceptor } from './agency.interceptor';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

export let myServiceFactory = () => {
  let agency = localStorage.getItem("agency");
  if (agency) {
    return `/agency/${agency}`.replace(/\s/g, "");
  }
  return null;
};

@NgModule({
  declarations: [
    AppComponent,
    ReferralFormComponent,
    HomeComponent,
    AuthComponent,
    ErrorComponent,
    ThankyouComponent,
    AuthenticationButtonComponent,
    SignupButtonComponent,
    LoginButtonComponent,
    LogoutButtonComponent,
    ProfileComponent,
    FormlyPhoneInput,
    AgencyHomeComponent,
    AgencyRegisterComponent,
    WelcomeComponent,
    PackagesWrapperComponent,
    PackageOptionComponent,  
    PackagesComponent,
    CheckoutComponent,
    ExternalPackagesComponent,
    UploadComponent,
    MySocialComponent
  ],
  imports: [
    BrowserModule,
    MyAuthModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      validationMessages:[
        {name: 'required', message: 'This field is required'}
      ], 
      extras: { 
        lazyRender: true 
      },
      types: [
        { name: 'phoneInput', component: FormlyPhoneInput },
      ],      
    }), 
    AuthModule.forRoot({
        ...env.auth,
        scope: 'read:current_user',
        httpInterceptor: {
          ...env.httpInterceptor,
        }
    }),
    NgxStripeModule.forRoot('pk_test_QrFtOTSMiwCKburEEOgNlOov'),
    NgxMaskModule.forRoot(options),   
    FormlyBootstrapModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)', 
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff', 
      secondaryColour: '#ffffff', 
      tertiaryColour: '#ffffff'
    })
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {provide: APP_BASE_HREF, useFactory: myServiceFactory},
    {provide: HTTP_INTERCEPTORS, useClass: AgencyInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
