<div class="row">
    <div class="item-service">
      <div class="col-md-3 icon-service bg-mask background-image" data-image="img/bg1.jpg">
        <div class="small-i"><i class="pe-7s-global"></i></div>
        <div class="large-i"><i class="pe-7s-global"></i></div>
      </div>
      <div class="col-md-9 content-service">
        <h2>Web <span>development</span></h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
        <hr>
        <div class="buttons-section left"><a class="btn white-btn" (click)="goToCheckout()">Get started</a></div>
      </div>
    </div>
  </div>
