import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'app-signup-button',
  templateUrl: './signup-button.component.html',
  styleUrls: ['./signup-button.component.sass']
})
export class SignupButtonComponent implements OnInit {
  @Input() isSignUpEnable: boolean | undefined;
  constructor(public auth: AuthService) { }

  ngOnInit(): void {
  }

  loginWithRedirect(): void {
    // this.auth.loginWithRedirect({screen_hint: 'signup' }, {appState: { target: '/register'}});
    this.auth.loginWithRedirect({appState: { target: '/register' }, screen_hint: 'signup' });

  }

}
