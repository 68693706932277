import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PackagesService } from '../services/packages.service';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.sass'],
})
export class CheckoutComponent implements OnInit {
  @ViewChild(StripeCardComponent)
  card!: StripeCardComponent;
  userDetailForm!: FormGroup;
  stripeTest!: FormGroup;
  showCheckoutForm: boolean = false;
  package: any;
  strikeCheckout: any = null;
  cardOptions: StripeCardElementOptions = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#eeeeee',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '15px',
        '::placeholder': {
          color: '#eeeeee',
        },
        backgroundColor: '#504645',
        lineHeight: '45px',
      },
    },
    hidePostalCode: true,
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };
  skipButtonDisable: boolean = true;
  customerId: string | null = '';

  constructor(
    public packagesService: PackagesService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private stripeService: StripeService,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.skipButtonDisable = true;
    let packageId = this.route.snapshot.params['id'];
    // this.package = packageId
    //   ? this.packagesService.getPackageById(packageId)
    //   : {};
      this.packagesService.getPackageById(packageId).subscribe((response:any) => {
        this.package = response[0];
      })
    this.customerId = this.packagesService.getCustomerId();
    this.buildStripeForm();
    this.buildUserDetaiForm();
    this.getBillingAddress();
  }

  buildStripeForm(): void {
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });
  }

  buildUserDetaiForm(): void {
    this.userDetailForm = this.fb.group({
      userEmail: this.fb.control(null, [
        Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
      ]),
      userPhone: this.fb.control(null, [
        Validators.required
      ]),
      userName: this.fb.control(null, [Validators.required]),
      address1: this.fb.control(null, [Validators.required]),
      address2: this.fb.control(null),
      city: this.fb.control(null, [Validators.required]),
      state: this.fb.control(null, [Validators.required]),
      zip: this.fb.control(null, [Validators.required]),
      id: this.fb.control(null),
      customerId: this.fb.control(null)
    });
  }

  createToken(): void {
    const controller = this.stripeTest.get('name');
    const name = controller?.value;
    this.stripeService
      .createToken(this.card.element, { name })
      .subscribe((result) => {
        if (result.token) {
          let stripeToken = result.token;
          // let payload = {
          //   amount: this.package.price + this.package.tax,
          //   token: stripeToken.id,
          //   currency: 'USD',
          //   recipientEmail: 'abc@devshield.com',
          //   description: this.stripeTest.controls['description'].value
          // };
          let payload = {
            Amount:this.package.price + this.package.tax,
            Currency:'usd',
            Source:'',
            receiptemail: this.userDetailForm.get('userEmail')?.value,
            Description: this.stripeTest.get('description')?.value,
            TokenId: stripeToken.id,
            PackageId: this.package.id,
            CustomerId: this.userDetailForm.get('customerId')?.value,
            BillingDetails:
            {
              CustomerId: this.userDetailForm.get('customerId')?.value,
              Name: this.userDetailForm.get('userName')?.value,
              PhoneNumber: this.userDetailForm.get('userPhone')?.value,
              BillingAddressCity:this.userDetailForm.get('city')?.value,
              BillingAddressZip:this.userDetailForm.get('zip')?.value,
              BillingAddressState:this.userDetailForm.get('state')?.value,
              BillingAddressLine1:this.userDetailForm.get('address1')?.value,
              BillingAddressLine2:this.userDetailForm.get('address2')?.value,
              billingemail:this.userDetailForm.get('userEmail')?.value,
              CreatedBy: null,
              UpdatedBy: null
            }
         }
         if(payload.CustomerId == null)
         {
          const customerId = this.getCustomerId();
            payload.CustomerId = customerId;
            payload.BillingDetails.CustomerId=customerId;
         }
          this.packagesService.saveStripeCheckout(payload).subscribe((res) => {
            this.notificationService.success("Payment Successfull");
            this.router.navigate(['/external-packages']);
          }, (error) => {
            this.notificationService.error("There was an error while making the payment");
          });
        } else if (result.error) {
          this.notificationService.error("There was an error while making the payment");
        }
      });
  }

  getStripeKey() {
    this.packagesService.getStripeKey().subscribe((res) => {
      
    });
  }

  getCustomerId() {
    return localStorage.getItem('customer_id');
  }
  
  skipUserForm(): void {
    this.showCheckoutForm = true;
  }

  inviteUser(data: any): void {
    const payload = {
      Id: this.userDetailForm.get('id')?.value,
      customerId: this.userDetailForm.get('customerId')?.value,
      name: this.userDetailForm.get('userName')?.value,
      phoneNumber: this.userDetailForm.get('userPhone')?.value,
      billingAddressCity: this.userDetailForm.get('city')?.value,
      billingAddressZip: this.userDetailForm.get('zip')?.value,
      billingAddressState: this.userDetailForm.get('state')?.value,
      billingAddressLine1: this.userDetailForm.get('address1')?.value,
      billingAddressLine2: this.userDetailForm.get('address2')?.value,
      billingEmail: this.userDetailForm.get('userEmail')?.value,
      // createdBy: null,
      // createdOn: null,
      // updatedBy: null,
      // updatedOn: null
    }
    if (payload.Id) {
      this.packagesService.updateBillingAddress(payload).subscribe((response) => {
        this.showCheckoutForm = true;
        this.createToken();
      }, (error)=> {
        console.error(error);
        this.notificationService.error("There was an error while inserting the billing details");
      });
    } else {
      delete payload.Id;
      if(payload.customerId == null) 
        {
          payload.customerId = this.customerId;
        }
      this.packagesService.insertBillingAddress(payload).subscribe(response => {
        this.showCheckoutForm = true;
        this.createToken();
      }, (error)=> {
        console.error(error);
        this.notificationService.error("There was an error while updating the billing details");
      });
    }
  }

  getBillingAddress() {
    this.packagesService.getBillingAddress().subscribe((res:any) => {
      const response = res[0];
      if (response) {
        this.userDetailForm.patchValue({
          userEmail: response.billingEmail,
          userPhone: response.phoneNumber,
          userName: response.name,
          address1: response.billingAddressLine1,
          address2: response.billingAddressLine2,
          city: response.billingAddressCity,
          state: response.billingAddressState,
          zip: response.billingAddressZip,
          id: response.id,
          customerId: response.customerId
        });
        this.skipButtonDisable = false;
      } else {
        this.skipButtonDisable = true;
      }
    });
  }
}
