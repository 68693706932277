import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(private _snackBar: MatSnackBar) {
    }

    default(message: string) {
        this.show(message, {
            duration: 3000,
            panelClass: 'default-notification-overlay',
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
    }

    info(message: string) {
        this.show(message, {
            duration: 3000,
            panelClass: 'info-notification-overlay',
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
    }

    success(message: string) {
        this.show(message, {
            duration: 3000,
            panelClass: 'success-notification-overlay',
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
    }

    warn(message: string) {
        this.show(message, {
            duration: 3000,
            panelClass: 'warning-notification-overlay',
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
    }

    error(message: string) {
        this.show(message, {
            duration: 3000,
            panelClass: 'error-notification-overlay',
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
    }

    private show(message: string, configuration: MatSnackBarConfig) {
        this._snackBar.open(message, '', configuration);
    }
}
