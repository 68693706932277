import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})

export class GoogleApisService {
    constructor(private http: HttpClient, public auth: AuthService) { }

    uploadFile(content: any) {
        const url = 'https://www.googleapis.com/upload/drive/v3/files?uploadType=media';
        let headers = new HttpHeaders({
            'Content-Type': 'text/csv'});
        let options = { headers: headers };
        return this.http.post(url, content.body, options);
    }
}