import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  constructor() { 
    const pathSplit: any[] = location.pathname ? location.pathname.split('/') : [];
    let flag = false;
    pathSplit.forEach(item => {
      if (item === 'agency') {
        flag = true;
      }
    });
    
    if (flag) {
      localStorage.clear();
      const redirectUrl = location.origin+`/home`
      window.location.replace(redirectUrl);
    }
  }

  ngOnInit(): void {
  }

}
