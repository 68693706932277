import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { take } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { PackagesService } from '../services/packages.service';

@Component({
  selector: 'app-external-packages',
  templateUrl: './external-packages.component.html',
  styleUrls: ['./external-packages.component.sass'],
})
export class ExternalPackagesComponent implements OnInit {
  packages: any = [];
  purchasedPackages: any[] = [];
  nonPurchasedPackages: any[] = [];
  hasApiError = false;
  constructor(
    private router: Router,
    private packagesService: PackagesService,
    private auth: AuthService,
    private notificationService: NotificationService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit(): void {
    this.auth.user$.pipe(take(1)).subscribe(
      (profile) => {
        if (profile) {
          this.packagesService.getCustomer(profile?.email).subscribe(response => {
            if (response) {
              if (!localStorage.getItem('customer_id')) {
                this.packagesService.setData(response);
              } else {}
              this.getPackages();
            }
          }, (error) => {
            this.notificationService.error("An error occurred while processing your request.");
          });
        } else {
          this.notificationService.error("Authorization timeout");
          this.logout();
        }
      })
  }

  getPackages(): void {
    this.packagesService.getPackages$().subscribe(response=>{
      this.packages = response;
      
      this.purchasedPackages = this.packages.filter(
        (item:any) => {
          return item.purchased == true? item: null;
          
        }
      );
      this.nonPurchasedPackages = this.packages.filter(
        (item:any) => {
          return item.purchased== false? item: null;
          
        }
      );
    });
  }

  goToCheckout(id: string): void {
    this.router.navigate([`/checkout/${id}`]);
  }

  logout(): void{
    localStorage.clear();
    this.auth.logout({returnTo: this.doc.location.origin });
  }
}
