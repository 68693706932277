<section class="section-our-services bg-dark-section" id="services">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-md-push-2">
        <div class="heading-title center">
          <h2>Our <span>services</span></h2>
          <div class="small-desd">We create <span>awesome stuff</span></div>
          <p>The combination of beautiful and clean design, technology and user experience, give us big advantage in the
            market and differentiates our work.</p>
        </div>
      </div>
    </div>
    <div class="d-flex packages-list">
      <div class="row w-80" *ngFor="let package of packages">
        <div class="item-service">
          <div class="col-md-12 content-service">
            <img class="container_img" src="{{package.ImageUrl}}">
            <div>
              <h2>{{package.Name}}</h2>
              <p>{{package.Description}}</p>
              <div class="total-price">Total price: ${{package.price + package.Tax}}</div>
              <div class="buttons-section left"><a class="btn white-btn"
                  (click)="goToCheckout(package._id)">{{package.ButtonText}}</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>