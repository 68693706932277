import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { AgencyService } from '../agency.service';
import { BehaviorSubject, Operator } from 'rxjs';
import { AgencyApiService } from 'src/app/agency-api.service';
import { AgencyRegisterComponent } from '../agency-register/agency-register.component';
import { PackagesService } from 'src/app/services/packages.service';
import { NotificationService } from 'src/app/services/notification.service';
import { take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-agency-home',
  templateUrl: './agency-home.component.html',
  styleUrls: ['./agency-home.component.sass']
})
export class AgencyHomeComponent implements OnInit {
  isLoginDisable: boolean = true;
  routeParams: Params | undefined;
  userProfile: any ;
  userRegistered$: any;
  hasApiError = false;
  public registrationComplete$ = new BehaviorSubject<boolean>(false);
  public loading$ = new BehaviorSubject<boolean>(true);
  checkedProfile: any;
  count: number = 0;
  actualParam: string = '';
  

  constructor(
    private activatedRoute: ActivatedRoute, 
    private agencyService: AgencyService,
    private agencyApi: AgencyApiService, 
    private router: Router, 
    public auth: AuthService, 
    private packagesService: PackagesService, 
    private notificationService: NotificationService,
    @Inject(DOCUMENT) private doc: Document) {
    const pathSplit: any[] = location.pathname ? location.pathname.split('/') : [];
    this.count = pathSplit.filter((obj) => obj == 'agency').length;
    
    let flag = false;
    pathSplit.forEach(item => {
      if (item && (flag === true)) {
        this.actualParam = item;
      }
      flag = item === 'agency' ? true : false;
    });
    this.getRouteParams();
   }

  ngOnInit(): void {
    this.userRegistered$ = new BehaviorSubject(false);
    this.setCustomerToLocalstorage();
  }

  getRouteParams() {
    this.activatedRoute.params.subscribe( params => {
      if (this.count > 1) {
        if (params.agency) {
          localStorage.clear();
          const redirectUrl = location.origin+`/agency/${this.actualParam}`
          window.location.replace(redirectUrl);
        }
      }
      
      const agencyName = localStorage.getItem("agency") ? localStorage.getItem("agency") : null;
      if (params.agency) {
        this.routeParams = params;
        localStorage.setItem('agency', params.agency);
        this.isLoginDisable = false
      } else if(agencyName) {
        this.routeParams = {agency: agencyName}
        this.isLoginDisable = false
      } else {
        this.isLoginDisable = true;
      }
      this.validateAgency();
    });
  }
  
  setCustomerToLocalstorage() {
    const agencyName = localStorage.getItem('agency');
    this.auth.user$.pipe(take(1)).subscribe((profile) => {
      this.userProfile = profile;
      if (this.userProfile != null) {
        this.packagesService.getCustomer(profile?.email)
          .subscribe((response: any) => {
            if (response) {
              if (!localStorage.getItem('customer_id')) {
                this.packagesService.setData(response);
                if (response.agency != agencyName) {
                  this.notificationService.error('Your agency could not match with current agency');
                  localStorage.removeItem('name');
                  localStorage.removeItem('customer_id');
                  setTimeout(() => {
                    this.auth.logout({returnTo: this.doc.location.origin });
                  }, 2000);
                } else {
                  this.validateRegistration();
                  this.router.navigate([`/`]);
                }
              } else {
                this.validateRegistration();
              }

            }
          }, (error) => {
            this.validateRegistration();
          });
      }
    })
  }

  validateAgency(){
    this.routeParams = {agency: localStorage.getItem("agency")}
    if (this.routeParams.agency) {
      this.agencyService.valAgency(this.routeParams)
        .subscribe((result) => {
          if (!result) {
            this.notificationService.error('Invalid agency');
            this.isLoginDisable = true;
            localStorage.removeItem("agency");
          }
        },
          (errorResponse) => {
            this.notificationService.error(errorResponse?.message);
            this.isLoginDisable = true;
          });
    } else {
      this.notificationService.error('Agency is not specified');
      this.isLoginDisable = true;
    }
  }
  


  validateRegistration(){
    var fullProfile
    this.auth.user$.pipe(take(1)).subscribe((profile) => {
      this.userProfile = profile;
      if(this.routeParams != null && this.userProfile != null)
      {
        fullProfile = {...this.userProfile , ...this.routeParams };
        this.checkedProfile = fullProfile;
        this.agencyApi.validateRegistration$(fullProfile).subscribe({
          next: (res) => {
            this.hasApiError = false;
            this.registrationComplete$.next(res);
            if(this.registrationComplete$.value == true)
            {
              this.userRegistered$.next(true);
              this.loading$.next(true);
              this.router.navigate(["/external-packages"]);
            } else {
              this.loading$.next(false);
            }      
          },
          error: () => this.hasApiError = true,
        });
      }
    })
  }

}
