<div class="slider" id="top">
    <div class="wrap-header">
      <!-- Start slide-->
      <div class="slide bg-mask background-image full-vh" >
            <div class="container-slide vertical-align center">
                <div class="container" *ngIf="(auth.isAuthenticated$ | async) === false">
                    <section class="display-page unity">
                        
                        
                            <div class="row" style="margin-top: 15%">
                                <!-- <div class="content">
                                    <h2>
                                        Welcome please login or if this is your first time here please register and we will get started
                                    </h2>
                                </div> -->
                                <div class="col-lg-1 col-xs-12">
                                    
                                </div>

                                <div class="col-lg-4 col-xs-12" style="border: 2px white solid; border-radius: 12px; padding-top: 50px; padding-bottom: 50px">
                                    <div class="content">
                                        <div class="post-thumb">
                                            <span class="material-icons" style="font-size: 65px">
                                                login
                                            </span>
                                        </div>
                                        <h3>Login to view your services</h3>
                                        <app-login-button [isLoginEnable]="isLoginDisable"></app-login-button>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-12">
                                    
                                </div>
                                <div class="col-lg-4 col-xs-12" style="border: 2px white solid; border-radius: 12px; padding-top: 50px; padding-bottom: 50px">
                                    <div class="content">
                                        <div class="post-thumb">
                                            <span class="material-icons" style="font-size: 65px">
                                                app_registration
                                            </span>
                                        </div>
                                        <h3>Signup to get started</h3>
                                    <app-signup-button [isSignUpEnable]="isLoginDisable"></app-signup-button>
                                    </div>
                                </div>

                                <div class="col-lg-1 col-xs-12">
                                    
                                </div>
                            </div>
                                        
                    </section>
                </div> 
                <div class="container" *ngIf="(auth.isAuthenticated$ | async)">
                    <div *ngIf="(registrationComplete$ | async) === false; then thenBlock;">
                        <div>Loading...</div> 
                    </div>
                    <ng-template #thenBlock>
                        <div *ngIf="(loading$ | async) === false">
                            <!-- <app-agency-register [profile]="checkedProfile" [registrationComplete]="registrationComplete$"></app-agency-register> -->
                        </div>
                    </ng-template>
                </div>


            </div>
        </div>
    </div>
</div>