<section class="section-our-services bg-dark-section" id="services">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-md-push-2">
        <div class="heading-title center">
          <h2 class="my-10">Our <span>services</span></h2>
          <div class="small-desd">We create <span>awesome stuff</span></div>
          <p>
            The combination of beautiful and clean design, technology and user
            experience, give us big advantage in the market and differentiates
            our work.
          </p>
        </div>
      </div>
    </div>

    <div class="d-flex packages-list">
      <h3 class="package-type">Purchased Packages</h3>
      <div class="row w-80" [ngClass]="{ 'packages-height': purchasedPackages.length > 2 }">
        <div *ngFor="let package of purchasedPackages">
          <div class="item-service h-100 w-100">
            <div class="col-md-12 content-service h-100">
              <img class="container_img" src="{{ package.imageUrl }}" />
              <div class="w-100">
                <span class="package-header">
                  <h2 class="package-name">{{ package.name }}</h2>
                  <div *ngIf="package.expirationDate" class="expiration-date">
                    Expiration Date: {{ package.expirationDate | date }}
                  </div>
                </span>
                <p>{{ package.description }}</p>
                <div class="total-price">
                  Total price: ${{ package.price + package.tax }}
                </div>
                <div class="buttons-section left" *ngIf="package.renew">
                  <!-- <a class="btn white-btn" (click)="goToCheckout(package._id)">{{
                    package.ButtonText
                  }}</a> -->
                  <a class="btn white-btn" (click)="goToCheckout(package.id)">
                    Renew
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex packages-list">
      <h3 class="package-type">Non Purchased Packages</h3>
      <div class="row w-80" [ngClass]="{ 'packages-height': nonPurchasedPackages.length > 2 }">
        <div *ngFor="let package of nonPurchasedPackages">
          <div class="item-service w-100">
            <!-- <div class="col-md-3 unpurchased-background-image" style="background-image: url('assets/img/bg1.jpg')">
              <img class="container_img" src="{{ package.ImageUrl }}" />
            </div> -->
            <div class="col-md-12 content-service">
              <img class="container_img" src="{{ package.imageUrl }}" />
              <div class="w-100">
                <h2>{{ package.name }}</h2>
                <p>{{ package.description }}</p>
                <div class="total-price">
                  Total price: ${{ package.price + package.tax }}
                </div>
                <div class="buttons-section left">
                  <a class="btn white-btn" (click)="goToCheckout(package.id)">Buy</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>